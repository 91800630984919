:root {
  --splash-blue: #1e1f4e;
  --active-blue: #2E5BFF;
  --active-green: #7ED321;
  --inactive-grey1: #9B9B9B;
  --inactive-grey2: #B0BAC9;
  --toggle-inactive-grey: #d2d2d2;
  --timer-grey: #858585;
  --timer-check-green: #22c064;
  --error-red: #bb0000;
  --text-grey: #8798AD;
  --title-grey: #505D68;
  --navbar-grey: #F2F5F7;
  --border-grey1: #979797;
  --border-grey2: #8e8e93;
  --border-blue: #007aff;
  --background-grey1: #E8E8E8;
  --background-grey2: rgb(128,151,177, 0.1);
  --background-grey3: #58546b;
  --boxshadow-green: #d6ead7;
  --login-text-grey: #b0bac9;
  --pale-grey: #FFFFFF;
  --deep-sky-blue-two:#007aff;
  --login-button-gradient: linear-gradient(281deg, #009ffd, #2a2a72);
  --login-button-shadow: rgba(0, 159, 253, 0.3);
  --gradient-grey: linear-gradient(90deg, rgba(51, 51, 51, 0.2), rgba(255,255,255,0.2));
  --gradient-blue1: linear-gradient(90deg, rgba(0, 122, 255, 0.2), rgba(46,91,255,0.2));
  --gradient-blue2: linear-gradient(180deg, #1754f0, #7d99eb 98%);
  --white: #FFFFFF;
  --black: #000000;
}

::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

h1, h4, h5 {
  margin: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 8;
  background-color: rgba(0,0,0,.3);
}

.overlay.notification {
  display: none;
}

.splash {
  width: calc(100vw - 40px);
  height: calc(100vh - 124px);
  background-color: var(--splash-blue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 62px 20px;
}

.splash > img {
  width: 100vw;
  margin-bottom: 75px;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 13px;
}

.login-form-password-retrieve {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5px;
}

.login-form-password-retrieve > a {
  color: var(--white);
  cursor: pointer;
  text-decoration: none;
}

.login-form-password-retrieve > a:hover {
  text-decoration: underline;
}

.login-form > p {
  color: var(--error-red);
}

.login-form-inputs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form-input-container {
  width: calc(100% - 30px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px var(--black);
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.login-form-input-container img {
  width: 37px;
  height: 37px;
  margin-right: 25px;
  z-index: 2;
}

.login-form-input-container input {
  height: 37px;
  font-size: 15px;
  color: var(--login-text-grey);
  background-color: transparent;
  border: none;
  z-index: 2;
}

.login-form-input-container input::placeholder {
  color: var(--login-text-grey);
}

.login-form-button {
  color: var(--white);
  width: 90%;
  font-size: 20px;
  padding: 14px 0;
  margin-top: 20px;
  box-shadow: 0 18px 40px 0 var(--login-button-shadow);
  background-image: var(--login-button-gradient);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navbar {
  position: fixed;
  top: 0;
  width: calc(100vw - 20px);
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--navbar-grey);
  transition: transform 0.5s;
}

.navbar.profile {
  display: initial;
}

.navbar.profile > h4 {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: normal;
  display: flex;
  justify-content: center;
}

.navbar img {
  cursor: pointer;
}

.navbar-icons {
  display: flex;
  align-items: center;
}

.navbar-icons img {
  margin: 0 10px;
}

.chat-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s;
  transform: translateX(-85vw);
  width: 85vw;
  min-height: 100vh;
  z-index: 10;
  background-color: var(--white);
}

.chat-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--navbar-grey);
}

.chat-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 20px);
  padding: 5px 10px;
  border-radius: 10px;
  background-color: var(--background-grey1);
}

.chat-search::placeholder {
  color: var(--inactive-grey2);
}

.chat-search > img {
  margin-right: 5px;
}

.chat-search > input {
  background-color: transparent;
  border: none;
  font-size: 18px;
  width: 100%;
}

.chat-group-container {
  margin: 18px 0;
}

.chat-group-container > h5 {
  font-size: 15px;
  font-weight: 400;
  padding: 10px 25px;
}

.chat-group-container > p {
  margin: 0 25px;
  color: var(--inactive-grey1);
}

.chat-group-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 19px 25px;
}

.chat-group-item:active {
  color: var(--active-blue);
  background-color: rgba(46, 91, 255, 0.1);
  border-left: 3px solid var(--active-blue);
}

.chat-group-item:active .chat-group-item-icon.Machines {
  background-image: url("images/analyticsActive.png")
}

.chat-group-item:active .chat-group-item-icon.Parts {
  background-image: url("images/starActive.png")
}

.chat-group-item:active .chat-group-item-icon.Jobs {
  background-image: url("images/checkActive.png")
}

.chat-group-item-icon {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  background-size: contain;
}

.chat-group-item-icon.Machines {
  background-image: url("images/analyticsInactive.png");
}

.chat-group-item-icon.Parts {
  background-image: url("images/starInactive.png")
}

.chat-group-item-icon.Jobs {
  background-image: url("images/checkInactive.png")
}

.chat-group-item > p {
  font-size: 15px;
  margin: 0;
}

.chat-item-container {
  margin-top: 58px;
  display: absolute;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
}

.chat-item-container > h5 {
  font-size: 0px;
  font-weight: 500;
  color: var(--inactive-grey1);
}

.chat-item-messages-container {
  margin-top: 10px;
  margin-bottom: 40px;
  padding: 10px 0;
  height: 500px;
  overflow-y: hidden;
  overflow: scroll;
  z-index: 10;
}

.chat-item-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 10px;
}

.chat-item-message {
  display: flex;
}

.chat-item-message > img {
  width: 57px;
  height: 50px;
  object-fit: contain;
  border: 1px solid var(--border-grey1);
  border-radius: 50%;
  margin-right: 5px;
}

.chat-item-bubble {
  position: relative;
  margin-top: 10px;
  display: flex;
  min-height: 50px;
}

.chat-item-bubble span {
  color: var(--background-grey1);
  position: absolute;
  bottom: 20px;
  left: -8px;
}

.chat-item-bubble p {
  font-size: 15px;
  margin: 0;
}

.chat-item-bubble.machine {
  width: calc(70vw - 40px);
  padding: 10px 20px;
  border-radius: 15px;
  background-color: var(--background-grey1);
}

.chat-item-bubble.user {
  align-items: center;
  width: calc(70vw - 50px);
  padding: 0 25px;
  border-radius: 18px;
  color: var(--white);
  background-image: var(--gradient-blue2);
}

.chat-item-time {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.chat-item-time > span {
  color: var(--timer-check-green);
  margin-right: 5px;
}

.chat-item-time > p {
  margin: 0;
  font-size: 12px;
  color: var(--timer-grey);
}

.chat-item-message-submit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: calc(100% - 40px);
  padding-top: 10px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--white);
  z-index: 10;
}

.chat-item-message-submit-recommendations {
  display: none;
  align-items: center;
  width: calc(100vw - 10px);
  overflow-x: hidden;
  overflow: scroll;
  margin-bottom: 5px;
}

.chat-item-message-submit-recommendations p {
  padding: 6px 7px;
  color: var(--active-blue);
  border: 1px solid var(--border-blue);
  border-radius: 3px;
  margin: 5px;
  white-space: nowrap;
  cursor: pointer;
}

.chat-item-message-submit-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 8;
}

.chat-item-message-submit-inputs {
  display: flex;
  align-items: center;
}

.chat-item-message-submit-inputs > input:nth-of-type(1) {
  font-size: 16px;
  border: none;
  width: 80%;
  margin: 0 20px;
}

.chat-item-message-submit-inputs > input::placeholder {
  font-size: 13px;
  letter-spacing: 1px;
  color: var(--black);
}

.chat-item-message-submit-button {
  width: 20%;
  font-size: 14px;
  letter-spacing: 1px;
  background-color: var(--white);
  border: none;
}

.profile-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.5s;
  transform: translateX(100vw);
  width: 85vw;
  min-height: 100vh;
  z-index: 10;
  background-color: var(--white);
}

.profile-user {
  display: flex;
  align-items: center;
  padding: 30px;
}

.profile-user > img {
  width: 45px;
  height: 45px;
  object-fit: contain;
  border-radius: 6px;
  background-color: var(--background-grey3);
  margin-right: 15px;
}

.profile-user > p {
  font-size: 15px;
  margin-bottom: 25px;
}

.profile-menus-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.profile-menu-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 19px 30px;
}

.profile-menu-container:active {
  color: var(--active-blue);
  background-color: rgba(46, 91, 255, 0.1);
  border-left: 3px solid var(--active-blue);
}

.profile-menu-container:active .profile-menu-icon.Notifications {
  background-image: url("images/starActive.png")
}

.profile-menu-container:active .profile-menu-icon.Settings {
  background-image: url("images/settingsActive.png")
}

.profile-menu-container:active .profile-menu-icon.Support {
  background-image: url("images/supportActive.png")
}

.profile-menu-container:active .profile-menu-icon.Logout {
  background-image: url("images/logoutActive.png")
}

.profile-menu-icon {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  background-size: contain;
}

.profile-menu-icon.Notifications {
  background-image: url("images/starInactive.png");
}

.profile-menu-icon.Settings {
  background-image: url("images/settingsInactive.png")
}

.profile-menu-icon.Support {
  background-image: url("images/supportInactive.png")
}

.profile-menu-icon.Logout {
  background-image: url("images/logoutInactive.png")
}

.profile-menu-container > p {
  font-size: 15px;
  margin: 0;
}

.profile-item-container {
  margin-top: 95px;
  padding-top: 30px;
}

.profile-item-input-separator {
  height: 1px;
  width: 82%;
  background-color: var(--toggle-inactive-grey);
}

.notifications-container {
  border-top: 1px solid var(--toggle-inactive-grey);
}

.notifications-do-not-disturb-container > p {
  color: var(--border-grey2);
  padding: 10px 15px;
  margin: 0;
  border-bottom: 1px solid var(--toggle-inactive-grey);
}

.settings-container {
  border-top: 1px solid var(--toggle-inactive-grey);
}

.settings-link-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.settings-link {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20vw;
  margin-bottom: 40px;
  word-break: break-all;
  text-decoration: none;
  color: var(--active-blue);
}

.settings-link:hover {
  text-decoration: underline;
}

.support-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 40px);
  padding: 30px 20px;
}

.support-container > h4 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 25px;
}

.support-form-textarea {
  font-size: 15px;
  min-height: 102px;
  width: calc(100% - 20px);
  padding: 24px 10px;
  margin-bottom: 20px;
  vertical-align: top;
  resize: none;
  word-wrap: break-word;
  border: 1px solid var(--border-grey1);
}

.support-form-textarea::placeholder {
  color: var(--border-grey1);
}

.logout-container {
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logout-container > p {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 25vw;
  text-align: center;
}

.logout-container > img {
  width: calc(100vw - 20px);
  height: calc(60vw - 20px);
}

.logout-buttons-container {
  display: flex;
  justify-content: space-around;
  padding: 0 20px;
  width: calc(100% - 40px);
}

.toggle-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 15px;
  width: calc(100% - 30px);
}

.toggle-input-container > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.toggle-input-slider {
  width: 50px;
  height: 30px;
  border-radius: 25px;
  background-color: var(--toggle-inactive-grey);
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.toggle-input-slider.toggled {
  background-color: var(--border-blue);
}

.toggle-input-circle {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 1px solid var(--toggle-inactive-grey);
  background-color: var(--white);
  position: absolute;
  left: 1px;
  top: 1px;
  transition: transform 0.3s;
  transform: translateX(0px);
}

.toggle-input-circle.toggled {
  transition: transform 0.3s;
  transform: translateX(20px);
  border: 1px solid var(--border-blue);
}

.collapse-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.collapse-input-container > div {
  display: flex;
  align-items: center;
  width: calc(100% - 30px);
  padding: 5px 15px;
}

.collapse-input-clickable {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.collapse-input-arrow {
  font-size: 25px;
  color: var(--toggle-inactive-grey);
}

.footer {
  position: fixed;
  bottom: 0;
  height: 75px;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--white);
  filter: blur(10);
}

.footer section {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.footer-text {
  margin-left: 10px;
  color: var(--inactive-grey2);
}

.footer-text.selected {
  color: var(--active-blue);
}

.main-container {
  transition: transform 0.5s;
}

.feed-container {
  margin-top: 54px;
  padding: 10px 0;
  position: relative;
}

.feed-cells-container {
  display: flex;
  align-items: center;
  width: calc(100% - 10px);
  margin-left: 10px;
  padding: 5px 0;
  overflow-x: hidden;
  overflow: scroll;
  z-index: 5;
  background-color: var(--white);
}

.feed-items-container {
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
  overflow: scroll;
}

.feed-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 10px;
  padding: 15px 23px;
  border-radius: 5px;
  cursor: pointer;
  height: 90px;
}

.feed-item > p {
  position: absolute;
  bottom: -12px;
  right: 5px;
}

.feed-indicator-dot {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -8px;
}

.feed-item-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
}

.feed-item-img {
  margin-bottom: 2.5px;
}

.feed-item-img.machlist {
  padding: 15px;
  border-radius: 50%;
  height: 30px;
  background-color: var(--background-grey2);
}

.feed-item-img.machitem {
  height: 150px;
}

.feed-item-img.timer {
  width: 35px;
  height: 39px;
}

.feed-item-timer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feed-item-timer-text > p {
  margin: 2.5px;
}

.feed-item-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.feed-item-text-wrapper p {
  margin: 0;
}

.feed-item-text-wrapper > p {
  font-weight: 500;
  color: var(--black);
  margin-bottom: 10px;
}

.machine-container {
  position: relative;
  margin-top: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 30px);
  padding: 22px 15px;
}

.machine-container > img {
  margin: 15px 0;
}

.machine-header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.machine-name {
  color: var(--title-grey);
  width: 150px;
  font-size: 28px;
  line-height: 38px;
  display: flex;
  justify-content: center;
}

.machine-buttons-container {
  display: flex;
  flex-wrap: wrap;
}

.machine-button-container {
  margin: 10px;
  width: calc(50vw - 35px);
  height: calc(50vw - 35px);
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.machine-button-container:active p {
  background-image: var(--gradient-blue1);
}

.machine-button-container > p {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 55%;
  background-image: var(--gradient-grey);
}

.inspection-container {
  z-index: 10;
  position: absolute;
  bottom: 40px;
  left: 0;
  background-color: var(--white);
  width: calc(100vw - 40px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inspection-container h4 {
  font-weight: 600;
  margin-bottom: 25px;
}

.inspection-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.inspection-body > input {
  margin-right: 25px;
  margin-top: 10px;
}

.inspection-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.inspection-input-container > p {
  font-weight: 600;
  text-align: center;
  width: calc(50vw - 20px);
}

.inspection-parts-input {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--inactive-grey2);
  border-radius: 3px;
  padding: 3px 10px;
  font-size: 18px;
  width: calc(50vw - 30px);
  height: 29px;
}

.inspection-parts-input > input {
  font-size: 16px;
  text-align: center;
  border: none;
  max-width: 75%;
}

.inspection-parts-input > div {
  cursor: pointer;
  font-size: 26px;
  color: var(--inactive-grey2);
}

.start-job-container {
  z-index: 10;
  position: absolute;
  bottom: 40px;
  left: 0;
  background-color: var(--white);
  width: calc(100vw - 90px);
  padding: 21px 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.start-job-container > h4 {
  margin-bottom: 30px;
}

.start-job-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 12;
  background-color: rgba(0,0,0,.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-job-modal-container {
  z-index: 15;
  background-color: var(--white);
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.56);
  border-radius: 5px;
  padding: 25px;
  margin: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.start-job-add {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 35px;
  height: 35px;
  object-fit: contain;
  cursor: pointer;
}

.start-job-arrow {
  position: absolute;
  top: 45%;
  font-size: 30px;
  color: var(--inactive-grey2);
  cursor: pointer;
}

.start-job-arrow:active {
  color: var(--active-blue);
}

.start-job-arrow.left {
  left: 10px;
}

.start-job-arrow.right {
  right: 10px;
}

.start-job-item-inputs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.start-job-item-input-container {
  width: 100%;
  margin-bottom: 15px;
}

.start-job-item-input-container > p {
  margin-top: 5px;
  margin-bottom: 10px;
}

.start-job-item-input {
  height: calc(40px - 6px);
  border: 1px solid var(--inactive-grey2);
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 3px 10px;
  font-size: 18px;
  width: calc(100% - 20px);
}

.start-job-item-input input {
  width: 90%;
  border: none;
  margin-right: 10px;
  font-size: 16px;
}

.start-job-tracker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.start-job-tracker-dot {
  font-size: 14px;
  color: var(--black);
  margin: 0 2px;
}

.start-job-tracker-dot.selected {
  color: var(--active-blue);
}

.camera-container {
  position: absolute;
  top: -54px;
  left: 0;
  width: 100vw;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
}

.camera-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(31, 31, 31, .2);
  width: calc(100% - 26px);
  padding: 25px 13px;
  position: relative;
}

.camera-header > span {
  font-size: 22px;
  position: absolute;
  left: 30px;
  cursor: pointer;
}

.camera-preview-wrapper {
  padding: 60px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.camera-preview {
  height: 40vh;
  width: 100%;
  object-fit: cover;
}

.camera-bottom {
  width: 100vw;
  padding: 12vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(31, 31, 31, .2);
  background-image: linear-gradient(180deg, rgba(31, 31, 31, .2), rgba(255, 255, 255, 0.2));
}

.camera-button-inner-circle {
  height: 44px;
  width: 44px;
  background: var(--white);
  z-index: 2;
  border-radius: 50%;
  border: none;
}
.camera-button-outer-circle {
  border-radius: 50%;
  height: 75px;
  width: 75px;
  background-color: hsla(0,0%,100%,.4);
  z-index: 1;
  border: none;
}

.preparation-checklist-container {
  z-index: 10;
  position: absolute;
  bottom: 20px;
  left: 0;
  background-color: var(--white);
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.56);
  width: 100vw;
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preparation-checklist-cells-container {
  display: flex;
  align-items: center;
  width: calc(100% - 10px);
  height: 40px;
  overflow-x: hidden;
  overflow: scroll;
  z-index: 5;
  background-color: var(--white);
  padding: 10px 0;
}

.preparation-checklist-body {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: calc(100% - 40px);
  padding: 20px;
}

.preparation-checklist-buttons-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 275px;
  margin-bottom: 35px;
}

.preparation-checklist-button-container {
  margin: 12.5px;
  padding: 6px;
  width: calc(50vw - 57px);
  height: calc(50vw - 57px);
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 var(--boxshadow-green);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.preparation-checklist-button-container.selected {
  background-image: radial-gradient(circle at 50% 50%, var(--white), var(--active-blue));
}

.preparation-checklist-button-container > p {
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  height: 65px;
}

.preparation-checklist-note-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 12;
  background-color: rgba(0,0,0,.3);
}

.preparation-checklist-note-container {
  z-index: 15;
  position: absolute;
  bottom: 20px;
  left: 0;
  background-color: var(--white);
  padding: 20px;
  width: calc(100vw - 40px);
}

.preparation-checklist-note-container > h5 {
  margin-bottom: 10px;
  font-weight: 600;
}

.preparation-checklist-note-container > textarea {
  min-height: 125px;
  width: calc(100% - 20px);
  resize: none;
  vertical-align: top;
  margin: 15px 0;
  padding: 10px;
  font-size: 16px;
}

.preparation-checklist-note-container > button {
  float: right;
}

.timer-container {
  z-index: 10;
  position: absolute;
  bottom: 40px;
  left: 0;
  background-color: var(--white);
  width: calc(100vw - 50px);
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timer-container.notification {
  position: static;
  bottom: auto;
  left: auto;
}

.timer-specs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  width: 100%;
  margin: 25px 0;
  position: relative;
}

.timer-selector-bar {
  position: absolute;
  top: 120px;
  left: 0;
  border-top: 1px solid var(--inactive-grey1);
  border-bottom: 1px solid var(--inactive-grey1);
  width: 100%;
  height: 25px;
}

.timer-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 10px;
  padding: 0 5px;
}

.timer-input-container > h4 {
  margin-bottom: 20px;
}

.timer-scrollable-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow: scroll;
  z-index: 2;
}

.timer-value {
  font-size: 20px;
  margin: 5px 0;
  color: var(--inactive-grey2);
}

.timer-value.selected {
  color: var(--black);
}

.confirmation-container {
  z-index: 10;
  position: absolute;
  bottom: 30px;
  left: 0;
  padding: 45px 0;
  background-color: var(--white);
  border-radius: 5px;
  width: 100vw;
  height: 285px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmation-container > * {
  margin: 15px 0;
}

.back-icon {
  font-size: 50px;
  font-weight: 200;
  line-height: 38px;
  cursor: pointer
}

.back-icon.machine {
  margin-right: calc(50vw - 108px);
}

.back-icon.profile {
  display: flex;
  align-items: center;
  line-height: 0;
  color: var(--border-blue);
  cursor: auto;
}

.back-icon.profile > span {
  font-size: 40px;
  font-weight: 300;
  cursor: pointer;
}

.back-icon.profile > p {
  font-size: 17px;
  font-weight: 400;
  margin-top: 25px;
  cursor: pointer;
}

.back-icon.profile > p:hover {
  text-decoration: underline;
}

.button-flex-end-wrapper {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
}

.form-submit-button {
  padding: 5px 0;
  width: 35vw;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid var(--active-blue);
  background-color: var(--white);
  border-radius: 8px;
}

.form-submit-button:active {
  background-image: var(--gradient-blue1);
}

.form-submit-button.grey {
  border: 2px solid var(--border-grey2);
}

.form-submit-button.hide {
  display: none;
}

.cell {
  width: 40px;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.3;
  border: 2px solid var(--inactive-grey1);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50), 0 2px 4px 0 rgba(0,0,0,0.50);
  padding: 3px 27.5px;
  margin: 0 5px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.cell.selected {
  opacity: 1;
  border: 2px solid var(--active-blue);
}

.switchView{
  margin-top:1em;
  font:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color:gray;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50), 0 2px 4px 0 rgba(0,0,0,0.50);
  color:black;
  opacity: .8;
  border: none;
  padding: 3px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  margin: 4px 4px;
  border-radius:2px;
}

.arrowRight{
  width: 35px;
  height: 35px;
  color: var(--deep-sky-blue-two);
  align-items: flex-end;
}

.listJobNumbers{
  border:solid;
  width: 100px;
  z-index:3;
  height: 100px;
}

/* loading dots */
.loading:after {
  content: ' .';
  animation: dots 3.5s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}
}
/* end of loading dots */

/* toggle chat */
.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 80px;
  height: 22px;
  padding: 3px;
  margin: 28px 10px -5px 0;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
  border-radius: 18px;
  box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 12px;
  text-transform: uppercase;
  background: #2196F3;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
}
.switch-label:before, .switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: black;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: black;
  text-shadow: 0 1px black;
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
   background: #eceeef;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-handle {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
  background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
  border-radius: 100%;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-input:checked ~ .switch-handle {
  left: 60px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
/* Transition
    ========================== */
.switch-label, .switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}

.toggleChatContainer {
  display:flex;
  margin-left:1em;
}

.toggleChatContainer h5 {
  margin-top:2em;
  margin-right:1em;
  font-weight: normal;
  color:black;
  font-size:15px;
}

